import React, { useEffect, useState, useRef } from "react";
import LogoBlock from "../components/logoBlock";
import { Box, Form, Grid, Text, Heading, Paragraph, Button } from "grommet";
import { FormNext, FormPrevious } from "grommet-icons";
import { usePageMargin } from "../helpers/hooks";
import styled from "styled-components";
import Img from "gatsby-image";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useMousePosition, useWindowDimensions } from "../helpers/hooks";
import Layout from "../components/layout";

import ReactSlider from "react-slider";
import Marquee from "../components/Marquee";

import { useSelector, useDispatch } from "react-redux";
import { initialLoad, moveForward, moveBack, loaded } from "../state/globalSlice";
import { setActiveSection, setScroll, resetSections } from "../state/scrollerSlice";
import useDynamicRefs from "use-dynamic-refs";

import CuriousScreen from "../pages/curious";
import GetItDoneScreen from "../pages/get-it-done";
import TakeItEasy from "../pages/take-it-easy";

const BottomMenu = () => {
  const dispatch = useDispatch();
  const activeSection = useSelector((state) => state.scroller.activeSection);
  const setActive = (section) => {
    dispatch(
      setActiveSection({
        section,
      })
    );
  };
  const nextSection = () => {
    dispatch(moveForward());
  };

  const prevSection = () => {
    dispatch(moveBack());
  };

  return (
    <Box style={{ bottom: 27, position: "absolute" }} direction="row" justify="center" fill="horizontal">
      <Button className="text-button noselect" plain label="BACK" onClick={prevSection} style={{ position: "absolute", left: 80 }} />
      <Box width="50%" alignSelf="center" direction="row" justify="center">
        <Box className={`menu-item ${activeSection === 0 && "active"}`} onClick={() => setActive(0)}>
          <Paragraph>TAKE IT EASY & RELAXED</Paragraph>
        </Box>
        <Box className={`menu-item ${activeSection === 1 && "active"}`} onClick={() => setActive(1)}>
          <Paragraph>GET IT DONE</Paragraph>
        </Box>
        <Box className={`menu-item ${activeSection === 2 && "active"}`} onClick={() => setActive(2)}>
          <Paragraph>STAY CURIOUS & ESCAPE</Paragraph>
        </Box>
      </Box>

      <Button className="text-button noselect" plain label="SKIP" onClick={nextSection} style={{ position: "absolute", right: 80 }} />
    </Box>
  );
};

const MouseCursor = () => {
  const dispatch = useDispatch();
  const canScroll = useSelector((state) => state.scroller.canScroll[state.scroller.activeSection]);

  let cursorMovementRef = useRef();
  let cursorSizeRef = useRef();
  const cursorRef = useRef();

  const position = useMousePosition();
  const { height, width } = useWindowDimensions();

  useEffect(() => {
    if (cursorRef.current) {
      cursorSizeRef.current = 10;

      if (position.y > height - 90 || position.y < 90) {
        gsap.to(cursorRef.current, {
          opacity: 0,
        });
      } else {
        gsap.to(cursorRef.current, {
          opacity: 1,
        });

        clearTimeout(cursorMovementRef.current);

        gsap.to(".label", { opacity: 0 });

        gsap.to(cursorRef.current, {
          left: position.x - cursorSizeRef.current / 2,
          top: position.y - cursorSizeRef.current / 2,
        });

        gsap.to(cursorRef.current, {
          width: cursorSizeRef.current,
          height: cursorSizeRef.current,
          duration: 0.5,
        });

        cursorMovementRef.current = setTimeout(() => {
          cursorSizeRef.current = 120;

          gsap.to(cursorRef.current, {
            width: cursorSizeRef.current,
            height: cursorSizeRef.current,
            left: position.x - cursorSizeRef.current / 2,
            top: position.y - cursorSizeRef.current / 2,
            duration: 1,
            ease: "elastic.out(0.5, 0.3)",
          });

          gsap.to(".label", { opacity: 1 });
        }, 500);
      }
    }
  }, [position]);

  const setThisScroll = (e) => {
    e.preventDefault();
    dispatch(setScroll());
  };

  return !canScroll ? (
    <Box
      onClick={setThisScroll}
      align="center"
      justify="center"
      ref={cursorRef}
      className="cursor"
      round="full"
      width="120px"
      height="120px"
      background="rgba(0,0,0,0.1)"
      style={{ position: "absolute", cursor: "pointer", pointerEvents: "auto", zIndex: 1000 }}
      border={{
        color: "white",
        size: "1px",
        style: "solid",
        side: "all",
      }}>
      <Text className="label" size="12px">
        EXPLORE
      </Text>
    </Box>
  ) : null;
};

const SideWaysPager = () => {
  const currentSection = useRef(0);
  const dispatch = useDispatch();
  const scrollerRef = useRef();

  const foo = ["scrollerRef_0", "scrollerRef_1", "scrollerRef_2"];
  const [getRef, setRef] = useDynamicRefs();

  const activeSection = useSelector((state) => state.scroller.activeSection);

  useEffect(() => {
    gsap.set(getRef(`scrollerRef_0`).current, { left: "100%" });
    gsap.set(getRef(`scrollerRef_1`).current, { left: "200%" });
    gsap.set(getRef(`scrollerRef_2`).current, { left: "0%" });
  }, []);

  useEffect(() => {
    const panels = {
      active: getRef(`scrollerRef_${currentSection.current}`).current,
      next: getRef(`scrollerRef_${activeSection}`).current,
    };

    let direction;

    if (currentSection.current !== activeSection) {
      console.log("not same");

      if (currentSection.current > activeSection) {
        console.log("moving right - setting next 200%");
        gsap.set(panels.next, { left: "0%" });
        direction = "+=100%";
      } else {
        console.log("moving left - setting next 0%");
        gsap.set(panels.next, { left: "200%" });
        direction = "-=100%";
      }
      currentSection.current = activeSection;

      gsap.to([panels.active, panels.next], {
        left: direction,
        duration: 1,
        onComplete: () => {
          dispatch(resetSections());
        },
      });
    }
  }, [activeSection]);

  return (
    <>
      <Box flex={false} direction="row" style={{ position: "relative", left: "-100%", width: "calc(100vw *3)" }}>
        <Box ref={setRef("scrollerRef_0")} flex={false} className={`scroller_panel`}>
          <TakeItEasy sectionNumber={0} />
        </Box>
        <Box ref={setRef("scrollerRef_1")} flex={false} className={`scroller_panel`}>
          <GetItDoneScreen sectionNumber={1} />
        </Box>
        <Box ref={setRef("scrollerRef_2")} flex={false} className={`scroller_panel`}>
          <CuriousScreen sectionNumber={2} />
        </Box>
      </Box>
      <BottomMenu />
      <MouseCursor />
    </>
  );
};

export default SideWaysPager;
