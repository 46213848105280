import React, { useState, useRef, useEffect } from "react";
import { Box, Spinner } from "grommet";
import Layout from "../components/layout";
import gsap from "gsap";
import Gui from "../components/Gui";

import Login from "../screens/login";
import {Loader_1, Loader_2, Loader_3 , Loader_4, Loader_5, ThankYou} from "../screens/loader";

import Video from "../screens/Video";
import ScrollSection from "../screens/ScrollSection";

import { useSelector, useDispatch } from "react-redux";
import { initialLoad, moveForward, moveBack, loaded } from "../state/globalSlice";

//PRELOAD
import loaderImage from "../images/loaders/Adidas_SPW_Wiper_01_alt.jpg";

const margin = 80;

const Panel = ({ scene, content, loader = null }) => {
  const global = useSelector((state) => state.global);
  const { sceneNumber, loading } = global;
  const scrollerRef = useRef();

  useEffect(() => {
    if (sceneNumber && sceneNumber === scene && loader) {
      loading ? gsap.set(scrollerRef.current, { top: 0 }) : onLoad();
    } else if (!loader) {
      gsap.set(scrollerRef.current, { top: "-100vh" });
    }
  }, [loading]);

  const onLoad = () => {
    gsap.to(scrollerRef.current, {
      top: "-100vh",
      duration: 1,
    });
  };

  return (
    <Box flex={false} height={`100vh`} width={`100vw`} style={{ overflow: "hidden", position: "relative" }}>
      <Box ref={scrollerRef} flex={false} height={`200vh`} width={`100vw`} style={{ position: "absolute" }}>
        <Box flex={false} height={`100vh`} width={`100vw`} style={{ position: "relative" }}>
          {loader}
        </Box>
        <Box flex={false} height={`100vh`} width={`100vw`} style={{ position: "relative" }}>
          <PreLoaders content={content} />
        </Box>
      </Box>
    </Box>
  );
};

const PreLoaders = ({ content }) => {
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    const imgs = [loaderImage];
    cacheImages(imgs);
  }, []);

  const cacheImages = async (srcArray) => {
    const promises = await srcArray.map((src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.onLoad = resolve();
        img.onerror = reject();
      });
    });
    await Promise.all(promises);
    setLoading(false);
  };

  return isLoading ? <Spinner /> : content;
};

const Framework = () => {
  const global = useSelector((state) => state.global);
  const { sceneNumber, loading } = global;
  const dispatch = useDispatch();
  const scrollerRef = useRef();
  const sceneRef = useRef(0);

  useEffect(() => {
    (sceneRef.current || sceneNumber) && scrollTo(sceneNumber, sceneRef.current < sceneNumber);
  }, [sceneNumber]);

  useEffect(() => {
    gsap.set(scrollerRef.current, { top: `-${100 * sceneNumber}vh` });

  }, []);

  const scrollTo = (position, forward) => {
    let nextPos = `${forward ? "-=" : "+="}${100}vh`;

    gsap.to(scrollerRef.current, {
      top: nextPos,
      duration: 1,
      onComplete: () => {
        sceneRef.current = position;
      },
    });
  };


  return (
    <>
      <Box height={`100vh`} width={`100vw`} style={{ overflow: "hidden", position: "absolute" }} background="black">
        <Box ref={scrollerRef} style={{ position: "absolute" }}>
          <Panel scene={0} background="black" content={<Login />}></Panel>
          <Panel scene={1} background="black" loader={<Loader_1 />} content={<Video play={sceneRef.current === 1}/>}></Panel>
          <Panel scene={2} background="black" loader={<Loader_2 />} content={<Video play={sceneRef.current === 2}/>}></Panel>
          <Panel scene={3} background="black" loader={<Loader_3 />} content={<Video play={sceneRef.current === 3}/>}></Panel>
          <Panel scene={4} background="black" loader={<Loader_4 />} content={<ScrollSection/>}></Panel>
          <Panel scene={5} background="black" loader={<Loader_5 />} content={<Video play={sceneRef.current === 5} />}></Panel>
          <Panel scene={6} background="black" content={<ThankYou />}></Panel>

        </Box>
      </Box>
      <Gui pager={sceneNumber > 0 && sceneNumber < 6} page={sceneNumber-1} />
    </>
  );
};


export default Framework;
