import React, { useEffect, useState, useRef } from "react";
import LogoBlock from "../components/logoBlock";
import { Box, Form, Grid, Text, Heading, Paragraph, Button } from "grommet";
import { FormNext, FormPrevious } from "grommet-icons";
import { usePageMargin } from "../helpers/hooks";
import styled from "styled-components";
import Img from "gatsby-image";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useMousePosition, useWindowDimensions } from "../helpers/hooks";
import Layout from "../components/layout";

import ReactSlider from "react-slider";
import Marquee from "../components/Marquee";

import backDrop from "../images/lookbook/get_it_done/hero/get_it_done_gradient.svg";
import backgroundImage from "../images/stay_curious/hero/rough/stay_curious_background@2x.png";
import foregroundImage from "../images/stay_curious/hero/rough/stay_curious_foreground@2x.png";
import middlegroundImage from "../images/stay_curious/hero/rough/stay_curious_middleground@2x.png";
import titleText from "../images/lookbook/get_it_done/get_it_done_title@2x.png";
import personImage from "../images/stay_curious/hero/stay_curious_person@2x.png";

import item_1 from "../images/lookbook/get_it_done/hero/get_it_done_item_1@2x.png";
import item_2 from "../images/lookbook/get_it_done/hero/get_it_done_item_2@2x.png";
import item_3 from "../images/lookbook/get_it_done/hero/get_it_done_item_3@2x.png";

import photo_1 from "../images/lookbook/get_it_done/get_it_done_1@2x.jpg";
import photo_2 from "../images/lookbook/get_it_done/get_it_done_2@2x.jpg";
import photo_3 from "../images/lookbook/get_it_done/get_it_done_3@2x.jpg";
import photo_4 from "../images/lookbook/get_it_done/get_it_done_4@2x.jpg";
import photo_5 from "../images/lookbook/get_it_done/get_it_done_5@2x.jpg";

import Volume from "../images/videoControls/ic_volume_up.inline.svg";
import PauseFill from "../images/videoControls/pause.inline.svg";

import { useSelector, useDispatch } from "react-redux";
import { initialLoad, moveForward, moveBack, loaded } from "../state/globalSlice";
import { setActiveSection, setScroll, resetSections } from "../state/scrollerSlice";

import useDynamicRefs from "use-dynamic-refs";

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger);
  gsap.core.globals("ScrollTrigger", ScrollTrigger);

  gsap.config({
    force3D: true,
    // units: {left: "%", top: "%", rotation: "rad"}
  });
}

const BackDrop = styled.div`
  background-image: ${(props) => `url("${props.src}")`};
  max-width: calc((100vw - 160px) * 0.9);
  width: 90%;
  height: 90%;
  left: 0;
  right: 0;
  padding: 50px;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-position: center top;
  position: absolute;
  top: 0;
  background-size: cover;
  z-index: 0;
  border-radius: 30px;
  overflow: hidden;
`;

const BackgroundImage = styled.div`
  background-image: ${(props) => `url("${props.src}")`};
  width: 100%;
  height: 0;
  padding-bottom: 47%;
  background-repeat: no-repeat;
  background-position: center top;
  position: absolute;
  bottom: 0;
  background-size: 95% auto;
  z-index: 2;
`;

const TitleText = styled.div`
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center top;
  position: absolute;
  top: 20%;
  background-size: 100% auto;
  z-index: 1;
`;

const MiddlegroundImage = styled.div`
  background-image: ${(props) => `url("${props.src}")`};
  width: 100%;
  background-repeat: no-repeat;
  background-position: center top;
  height: 0;
  padding-bottom: 26.3%;
  position: absolute;
  bottom: 5px;
  background-size: 98% auto;
  z-index: 3;
`;

const ForegroundImage = styled.div`
  background-image: ${(props) => `url("${props.src}")`};
  width: 100%;
  height: 0;
  padding-bottom: 26.3%;
  background-repeat: no-repeat;
  background-position: center top;
  position: absolute;
  bottom: 10px;
  background-size: auto 100%;
  z-index: 4;
`;

const Person = styled.div`
  background-image: ${(props) => `url("${props.src}")`};
  background-repeat: no-repeat;
  background-position: center top;
  position: absolute;
  bottom: 7%;
  padding-bottom: 45%;
  height: 0;
  width: 15%;
  margin: 0 auto;
  left: 0;
  right: 0;
  background-size: 100% auto;
  z-index: 5;
  pointer-events: "auto";
`;

const PersonComponent = React.forwardRef(({ src, style }, ref) => {
  return (
    <Person ref={ref} src={src} style={style}>
      <Box style={{ position: "absolute", bottom: 0 }} fill="horizontal">
        <ReactSlider
          className="horizontal-slider"
          thumbClassName="slider-thumb"
          renderTrack={(props) => (
            <Box
              {...props}
              background="transparent"
              style={{ position: "absolute", top: "25px", width: "100%", pointerEvents: "auto" }}
              border={{
                color: "white",
                size: "1px",
                style: "dashed",
                side: "top",
              }}></Box>
          )}
          renderThumb={(props, state) => (
            <Box
              justify="center"
              align="center"
              direction="row"
              height="48px"
              width="48px"
              border={{
                color: "white",
                size: "1px",
                style: "solid",
                side: "all",
              }}
              background="black"
              round
              {...props}>
              <FormPrevious style={{ position: "relative", left: 4 }} size="20px" />
              <FormNext style={{ position: "relative", right: 4 }} size="20px" />
            </Box>
          )}
        />
      </Box>
    </Person>
  );
});

const Hero = React.forwardRef(({ sectionNumber, canScroll }, ref) => {
  const position = useMousePosition();
  const { height, width } = useWindowDimensions();

  const constrain = 3000;
  const containerRef = useRef();
  const marqueeRef = useRef();

  const [foregroundRotateStyle, setForeGroundRotateStyle] = useState();
  const [middlegroundRotateStyle, setMiddlegroundRotateStyle] = useState();
  const [backgroundRotateStyle, setBackgroundRotateStyle] = useState();
  const [textRotateStyle, setTextRotateStyle] = useState();
  const [rotateStyle, setRotateStyle] = useState();

  const q = gsap.utils.selector(containerRef);

  useEffect(() => {
    let box = containerRef.current.getBoundingClientRect();
    let calcX = !canScroll ? -(position.y - box.y - box.height / 2) / constrain : 0;
    let calcY = !canScroll ? (position.x - box.x - box.width / 2) / constrain : 0;

    setTextRotateStyle("perspective(600px) translate3D(0, 0, -5px)" + "   rotateX(" + calcX + "deg) " + "   rotateY(" + calcY + "deg) ");
    setBackgroundRotateStyle("perspective(500px) translate3D(0, 0, -2px)" + "   rotateX(" + calcX + "deg) " + "   rotateY(" + calcY + "deg) ");
    setMiddlegroundRotateStyle("perspective(300px) translate3D(0, 0, -1px)" + "  rotateX(" + calcX + "deg) " + "   rotateY(" + calcY + "deg) ");
    setForeGroundRotateStyle("perspective(200px) translate3D(0, 0, 1px)" + "  rotateX(" + calcX + "deg) " + "   rotateY(" + calcY + "deg) ");
    setRotateStyle(!canScroll ? "perspective(300px) " + " translate(-50%, 0)  rotateX(" + calcX + "deg) " + "   rotateY(" + calcY + "deg) " : "translate(-50%, 0)");
  }, [position, height, width]);

  useEffect(() => {
    gsap.set(q(".fade"), { opacity: 1 });
    gsap.set(marqueeRef.current, { position: "relative", left: 0 });
    gsap.to(marqueeRef.current, { left: "-100%", repeat: -1, duration: 30, ease: "none" });
  }, []);

  useEffect(() => {
    gsap.to(q(".fade"), { opacity: canScroll ? 0 : 1 });
    gsap.to(".horizontal-slider", { opacity: canScroll ? 1 : 0 });
  }, [canScroll]);

  return (
    <div style={{ top: 0, left: 0, position: "absolute", width: "100%", height: "100%", pointerEvents: "none" }}>
      <div
        ref={containerRef}
        style={{
          transform: rotateStyle,
          height: "calc(100vh - 160px)",
          width: "calc((100vh - 160px) * 1.7)",
          left: "50%",
          margin: "auto",
          position: "absolute",
          pointerEvents: "none",
        }}>
        <BackDrop className="fade" src={backDrop}>
          {/* <h1>{sectionNumber}</h1> */}
        </BackDrop>
        <TitleText style={{ transform: textRotateStyle }}>
          <Box direction="row" className="marquee" ref={marqueeRef}>
            <img src={titleText} width="100%" />
            <img src={titleText} width="100%" />
          </Box>
        </TitleText>
        <BackgroundImage className="fade" src={backgroundImage} style={{ transform: backgroundRotateStyle }} />
        <PersonComponent ref={ref} src={personImage} style={{ transform: middlegroundRotateStyle }} />
        <MiddlegroundImage className="fade" src={middlegroundImage} style={{ transform: middlegroundRotateStyle }} />
        <ForegroundImage className="fade" src={foregroundImage} style={{ transform: foregroundRotateStyle }} />
      </div>
    </div>
  );
});

const RoundImage = React.forwardRef(({ src }, ref) => {
  return (
    <Box ref={ref} round fill="horizontal" style={{ overflow: "hidden", zIndex: 1000, position: "relative" }}>
      <img src={src} width="100%" />
    </Box>
  );
});

const ItemImage = React.forwardRef(({ src, ...rest }, ref) => {
  return (
    <Box {...rest} ref={ref}>
      <img src={src} />
    </Box>
  );
});

const CuriousScreen = React.forwardRef(({ sectionNumber, style }, ref) => {
  const canScroll = useSelector((state) => state.scroller.canScroll[sectionNumber]);
  const heroRef = useRef(null);
  const scrollerRef = useRef(null);

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);

  const itemRef1 = useRef(null);
  const itemRef2 = useRef(null);
  const itemRef3 = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      gsap.set(ref1.current, { yPercent: 0, x: 0 });
      gsap.set(ref2.current, { yPercent: 0, x: 0 });
      gsap.set(ref3.current, { yPercent: 0, x: 0 });
      gsap.set(ref4.current, { yPercent: 0, x: 0 });
      gsap.set(ref5.current, { yPercent: 0, x: 0 });
      gsap.set(ref6.current, { yPercent: 0, x: 0 });

      gsap.set(itemRef1.current, { transformOrigin: "50% 50%", yPercent: -10, xPercent: -50 });
      gsap.set(itemRef2.current, { transformOrigin: "50% 50%", yPercent: -25, xPercent: 30 });
      gsap.set(itemRef3.current, { transformOrigin: "50% 50%", yPercent: 0, xPercent: 0 });

      gsap.to(heroRef.current, {
        scrollTrigger: {
          trigger: ref1.current,
          scroller: scrollerRef.current,
          start: "top bottom",
          end: "top bottom-=500px",
          // markers: true,
          // toggleActions: "restart pause restart restart",
          scrub: true,
        },
        opacity: 0,
        ease: "none",
      });

      gsap.to(ref1.current, {
        scrollTrigger: {
          trigger: ref1.current,
          scroller: scrollerRef.current,
          start: "top bottom",
          // end: "bottom top-=1000px",
          scrub: true,
        },
        yPercent: -50,
        ease: "none",
      });

      gsap.to(ref2.current, {
        scrollTrigger: {
          trigger: ref2.current,
          scroller: scrollerRef.current,
          start: "top bottom",
          // end: "bottom top-=1000px",
          scrub: true,
        },
        yPercent: -50,
        ease: "none",
      });

      gsap.to(ref4.current, {
        scrollTrigger: {
          trigger: ref4.current,
          scroller: scrollerRef.current,
          start: "top bottom",
          // end: "bottom top-=1000px",
          scrub: true,
        },
        yPercent: -10,
        ease: "none",
      });

      gsap.to(itemRef1.current, {
        scrollTrigger: {
          trigger: itemRef1.current,
          scroller: scrollerRef.current,
          start: "top bottom",
          // end: "bottom top-=1000px",
          scrub: true,
        },
        yPercent: 50,
        rotation: "+=50",
        ease: "none",
      });

      gsap.to(itemRef2.current, {
        scrollTrigger: {
          trigger: itemRef2.current,
          scroller: scrollerRef.current,
          start: "top bottom",
          // end: "bottom top-=1000px",
          scrub: true,
        },
        rotation: "+=-50",
        yPercent: 50,
        ease: "none",
      });

      gsap.to(itemRef3.current, {
        scrollTrigger: {
          trigger: itemRef3.current,
          scroller: scrollerRef.current,
          start: "top bottom",
          // end: "bottom top-=1000px",
          scrub: true,
        },
        rotation: "+=50",
        yPercent: 50,
        ease: "none",
      });
    }, 500);
  }, []);

  useEffect(() => {
    scrollerRef.current.scrollTop = 0;
  }, [canScroll]);

  return (
    <Box ref={ref} flex={false} style={{ position: "relative", width: "calc(100vw - 160px)", marginLeft: 80, marginRight: 80, ...style }}>
      <div
        className="scroll-section"
        style={{ background: "black", top: 80, left: 0, height: "calc(100vh - 160px)", width: "calc(100vw - 160px)", position: "absolute", overflow: "hidden" }}>
        <Box ref={scrollerRef} style={{ height: "100%", width: "100%", overflowY: canScroll ? "scroll" : "hidden", position: "relative" }}>
          <Box width={{ max: "1600px" }} pad={{ top: "100vh" }} flex="grow" alignSelf="center">
            <Grid columns={["flex", "flex"]} gap="xlarge" fill="horizontal" pad="xlarge">
              <ItemImage ref={itemRef1} width={{ max: "500px" }} src={item_1} />
              <RoundImage ref={ref1} src={photo_1} className="parallax" />
              <RoundImage ref={ref2} src={photo_2} className="parallax" />
              <Box></Box>
              <RoundImage ref={ref3} src={photo_3} className="parallax" />
              <ItemImage ref={itemRef2} width={{ max: "340px" }} src={item_2} />
              <ItemImage ref={itemRef3} width={{ max: "320px" }} src={item_3} />
              <RoundImage ref={ref4} src={photo_4} className="parallax" />
              <RoundImage ref={ref5} src={photo_5} className="parallax" />
            </Grid>
            <VideoBlock />
            <TextBlock />
            <Heading level="2" size="xlarge" alignSelf="center" margin={{ top: "300px", bottom: "40vh" }} flex="grow">
              COMING FW22
            </Heading>
          </Box>
        </Box>
        <Hero ref={heroRef} canScroll={canScroll} setScroll={setScroll} sectionNumber={sectionNumber}></Hero>
      </div>
    </Box>
  );
});



const VideoBlock = () => {
  return (
    <Box style={{ position: "relative" }} margin={{ vertical: "300px" }} alignSelf="center" flex="grow">
      <RoundImage src={photo_5} className="parallax" />
      <Box style={{ position: "absolute", bottom: 60, left: 60, width: "calc(100% - 120px)", zIndex: 1001 }} height="60px" direction="row" align="center" justify="center">
        <PauseFill color="white" />
        <Box style={{ position: "relative", width: "100%" }} height="2px" background="#666666" margin={{ horizontal: "20px" }}>
          <Box style={{ position: "absolute", top: 0, left: 0 }} width="50%" height="2px" background="white" />
        </Box>
        <Box className="video-time-container" align="center" justify="center">
          <Paragraph className="video-time">01:00 | 09:32</Paragraph>
        </Box>
        <Volume color="white" style={{ top: 2, position: "relative" }} />
      </Box>
    </Box>
  );
};

const TextBlock = () => {
  return (
    <Box alignSelf="center" width={{ max: "600px" }} margin={{ vertical: "300px" }} align="center" basis="auto" flex="grow">
      <Paragraph color="white" textAlign="center">
        Stay Curious
      </Paragraph>
      <Paragraph color="white" textAlign="center">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vitae proin sagittis nisl rhoncus mattis
        rhoncus. Morbi enim nunc faucibus a pellentesque sit.
      </Paragraph>
      <Paragraph color="white" textAlign="center">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vitae proin sagittis nisl rhoncus mattis
        rhoncus. Morbi enim nunc faucibus a pellentesque sit.
      </Paragraph>
    </Box>
  );
};


export default CuriousScreen;
